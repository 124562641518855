:root {
  --Rubik:
    Rubik, Rubik-fallback, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

/* customed Arial fallback font
https://deploy-preview-15--upbeat-shirley-608546.netlify.app/posts/high-performance-web-font-loading/
https://www.smashingmagazine.com/2021/05/reduce-font-loading-impact-css-descriptors/
 */
@font-face {
  font-family: "Rubik-fallback";
  size-adjust: 105.03%;
  ascent-override: 90%;
  src: local("Arial");
}

/* cyrillic-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 300;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXO61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range:
    U+0460-052F,
    U+1C80-1C88,
    U+20B4,
    U+2DE0-2DFF,
    U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 300;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXq61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 300;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXy61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 300;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range:
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 300;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format("woff2");
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXO61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range:
    U+0460-052F,
    U+1C80-1C88,
    U+20B4,
    U+2DE0-2DFF,
    U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXq61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXy61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range:
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format("woff2");
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 500;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXO61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range:
    U+0460-052F,
    U+1C80-1C88,
    U+20B4,
    U+2DE0-2DFF,
    U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 500;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXq61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 500;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXy61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 500;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range:
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 500;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format("woff2");
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 600;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXO61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range:
    U+0460-052F,
    U+1C80-1C88,
    U+20B4,
    U+2DE0-2DFF,
    U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 600;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXq61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 600;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXy61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 600;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range:
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 600;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format("woff2");
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXO61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range:
    U+0460-052F,
    U+1C80-1C88,
    U+20B4,
    U+2DE0-2DFF,
    U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXq61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXy61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range:
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format("woff2");
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 800;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXO61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range:
    U+0460-052F,
    U+1C80-1C88,
    U+20B4,
    U+2DE0-2DFF,
    U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 800;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXq61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 800;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXy61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 800;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range:
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 800;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format("woff2");
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 900;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXO61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range:
    U+0460-052F,
    U+1C80-1C88,
    U+20B4,
    U+2DE0-2DFF,
    U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 900;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXq61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 900;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXy61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 900;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnXC61E_c5IhGzg.woff2)
    format("woff2");
  unicode-range:
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: var(--Rubik);
  font-style: italic;
  font-weight: 900;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWEBXyIfDnIV7nEnX661E_c5Ig.woff2) format("woff2");
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format("woff2");
  unicode-range:
    U+0460-052F,
    U+1C80-1C88,
    U+20B4,
    U+2DE0-2DFF,
    U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format("woff2");
  unicode-range:
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nBrXyw023e.woff2) format("woff2");
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format("woff2");
  unicode-range:
    U+0460-052F,
    U+1C80-1C88,
    U+20B4,
    U+2DE0-2DFF,
    U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format("woff2");
  unicode-range:
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nBrXyw023e.woff2) format("woff2");
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format("woff2");
  unicode-range:
    U+0460-052F,
    U+1C80-1C88,
    U+20B4,
    U+2DE0-2DFF,
    U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format("woff2");
  unicode-range:
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nBrXyw023e.woff2) format("woff2");
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format("woff2");
  unicode-range:
    U+0460-052F,
    U+1C80-1C88,
    U+20B4,
    U+2DE0-2DFF,
    U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format("woff2");
  unicode-range:
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nBrXyw023e.woff2) format("woff2");
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format("woff2");
  unicode-range:
    U+0460-052F,
    U+1C80-1C88,
    U+20B4,
    U+2DE0-2DFF,
    U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format("woff2");
  unicode-range:
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nBrXyw023e.woff2) format("woff2");
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 800;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format("woff2");
  unicode-range:
    U+0460-052F,
    U+1C80-1C88,
    U+20B4,
    U+2DE0-2DFF,
    U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 800;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 800;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 800;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format("woff2");
  unicode-range:
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 800;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nBrXyw023e.woff2) format("woff2");
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 900;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format("woff2");
  unicode-range:
    U+0460-052F,
    U+1C80-1C88,
    U+20B4,
    U+2DE0-2DFF,
    U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 900;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* hebrew */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 900;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format("woff2");
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin-ext */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 900;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format("woff2");
  unicode-range:
    U+0100-024F,
    U+0259,
    U+1E00-1EFF,
    U+2020,
    U+20A0-20AB,
    U+20AD-20CF,
    U+2113,
    U+2C60-2C7F,
    U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: var(--Rubik);
  font-style: normal;
  font-weight: 900;
  font-display: fallback;
  src: url(/fonts/rubik/v20/iJWKBXyIfDnIV7nBrXyw023e.woff2) format("woff2");
  unicode-range:
    U+0000-00FF,
    U+0131,
    U+0152-0153,
    U+02BB-02BC,
    U+02C6,
    U+02DA,
    U+02DC,
    U+2000-206F,
    U+2074,
    U+20AC,
    U+2122,
    U+2191,
    U+2193,
    U+2212,
    U+2215,
    U+FEFF,
    U+FFFD;
}
